






















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import BeatTable from '@/components/BeatTable.vue'
import HeaderSection from '@/components/HeaderSection.vue'
import TileSlider from '@/components/TileSlider.vue'
import ListEmptyMessage from '~/components/ListEmptyMessage.vue'
import { UserStore } from '@/store/user'
import { AuthStore } from '~/store/auth'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable, HeaderSection, TileSlider, ListEmptyMessage } })
export default class FavouritesPage extends Vue {
    @UserStore.State('followedPlaylists') followedPlaylists: IFollowedPlaylist[]
    @UserStore.Getter('likedBeatsMapped') likedBeatsMapped: IBeat[]
    @AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

    ParentStateType = ParentStateType

    get playlists(): IPlaylist[] {
        // map to only grab the playlist obj
        return this.followedPlaylists.length > 0 ? this.followedPlaylists.map(item => item.playlist) : null
    }

    @UserStore.Action('fetchLikedBeats') fetchLikedBeats: () => void

    created() {
        if (this.isAuthenticated) this.fetchLikedBeats()
    }
}
