var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: {
          heading: "Favourites",
          subheading:
            "Hear the tracks you’ve liked and playlists you've followed.",
          img: "bg-like",
        },
      }),
      !_vm.isAuthenticated
        ? [
            _c(
              "section",
              { staticClass: "section" },
              [
                _c("list-empty-message", {
                  attrs: {
                    "icon-name": "heart",
                    title: "Ops! Looks like you are not logged in",
                    subtitle:
                      "Log in to see your liked beats or add new ones to the list.",
                    "link-to": "Login",
                  },
                }),
              ],
              1
            ),
          ]
        : [
            _vm.playlists && _vm.playlists.length > 0
              ? _c(
                  "section",
                  {
                    staticClass: "section",
                    staticStyle: { "margin-bottom": "60px" },
                  },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
                        _vm._v("Playlists"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "wrapper" },
                        [
                          _c("tile-slider", {
                            attrs: {
                              "parent-state": "playlists",
                              "loaded-items": _vm.playlists,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _vm.playlists && _vm.playlists.length > 0
                    ? _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
                        _vm._v("Liked Beats"),
                      ])
                    : _vm._e(),
                  _c("BeatTable", {
                    attrs: {
                      parentState: _vm.ParentStateType.Favourites,
                      beatPlaylist: _vm.likedBeatsMapped,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }